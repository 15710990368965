.p-chips .p-chips-multiple-container {
    width: 100%;
}

.p-autocomplete > input {
    width: 100%;
}

.float-export-button {
    .p-speeddial-direction-up {
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 1rem;
        z-index: 101;
    }
}
