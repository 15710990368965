.layout-footer {
    transition: margin-left $transitionDuration;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--surface-border);
    gap: 10px;
    height: 5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 2rem;
    width: 100%;
}
