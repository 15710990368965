:root {
    --primary-color: #A59466;
}
.color-success {
    color: #28a745;
}

$black: #000;
$gray-900: #141414;
$gray-800: #1f1f1f;
$gray-700: #333333;
$gray-600: #545454;
$gray-500: #757575;
$gray-450: #9c9c9c;
$gray-400: #afafaf;
$gray-300: #cbcbcb;
$gray-200: #e2e2e2;
$gray-100: #eee;
$gray-50: #f6f6f6;
$white: #fff;
$primary-100: #FEDCDE;
$primary-300: #D8BFAA;
$primary-500: #A59466;
$action: #0088CE;
$action-hover: #0067a7;
$success: #27AE60;
$error: #EB5757;
