/* You can add global styles to this file, and also import other style files */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "assets/sass/theme.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";

/* Import Bootstrap SCSS before any custom styles */
@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../node_modules/bootstrap/dist/css/bootstrap-grid.css";

@import "assets/demo/flags/flags.css";
@import "assets/demo/documentation.scss";
@import "assets/sass/layout/_variables";
@import "assets/sass/layout/_layout";
@import "assets/sass/overrides/_overrides";
