.clickable {
    cursor: pointer;
}

a.clickable {
    transition: all .4s;
}

a.clickable:hover, h6.clickable:hover {
    text-decoration: underline;
}

.italic {
    font-style: italic;
}
