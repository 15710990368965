:root {
  --primary-color: #A59466;
}

.color-success {
  color: #28a745;
}

input.p-element {
  width: 100%;
}

textarea {
  width: 100%;
  resize: none;
}

.p-calendar {
  width: 100%;
}

.p-element > .p-dropdown {
  width: 100%;
}

.p-element > .p-chips {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

a.clickable {
  transition: all 0.4s;
}

a.clickable:hover, h6.clickable:hover {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}