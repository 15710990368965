// Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future
@import "_layout.scss";
@import "colors.scss";
@import "primeng-elements";

.layout-topbar {
    background-color: var(--header-bg);
    color: var(--header-text-color);
    border-bottom-width: 4px;
    border-bottom-color: var(--header-border-color) !important;
    border-bottom-style: solid;
}

.layout-sidebar {
    background-color: var(--sidebar-bg);
    color: var(--header-text-color);
}

.layout-footer {
    background-color: var(--footer-bg);
    color: var(--footer-text-color);
}
